@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --navbar-height: 90px;
  --product-card-sizer-count: 1;
}

@font-face {
  font-family: "SignPainter";
  src: url("../../public/fonts/SignPainter-HouseScript.otf");
}

@layer base {
  html {
    height: 100%;
    font-family: var(--poppins), sans-serif;
  }

  body {
    height: 100%;
  }

  #__next {
    height: 100%;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }
}
@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
@layer components {
  .header-bg {
    background-image: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0) 87%);
  }

  .footer-bg {
    background-image: linear-gradient(93deg, #280722 0%, #880865 103%);
  }

  .shadow-creator-footer {
    box-shadow: 0px -4px 25px rgba(49, 49, 49, 0.05);
  }

  .creator-grid {
    min-width: 0;
    min-height: 0;
    background-color: #fff;
    display: grid;
    padding-top: 1rem;
    grid-template-areas:
      ". categories-dropdown preview-button ."
      ". customization-bar customization-bar ."
      ". content content ."
      "footer footer footer footer";
    grid-template-rows: max-content max-content 1fr max-content;
    grid-template-columns: 0.5rem 1fr 1fr 0.5rem;
  }

  .creator-card--active {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23880865' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='26' stroke-linecap='square'/%3e%3c/svg%3e");
    background-position: center;
  }

  .cart-item-grid {
    display: grid;
    grid-template-areas:
      "cart-item-image cart-item-name cart-item-name"
      "cart-item-image cart-item-category cart-item-category"
      "cart-item-image cart-item-customizations cart-item-customizations"
      "cart-item-image cart-item-quantity cart-item-quantity"
      "cart-item-image cart-item-price cart-item-price"
      "cart-item-actions cart-item-actions cart-item-actions";
    grid-template-columns: min-content 1fr 1fr;
  }

  @media screen and (min-width: 640px) {
    .creator-grid {
      padding-top: 0;
      grid-template-areas:
        "customization-bar . . preview-bar"
        "customization-bar content-header categories-dropdown preview-bar"
        "customization-bar content content preview-bar"
        "customization-bar footer footer footer";
      grid-template-columns: minmax(185px, max-content) 3fr 2fr 290px;
      grid-template-rows: 1.5rem minmax(0, max-content) minmax(0, 1fr) minmax(0, max-content);
    }

    .shadow-creator-footer {
      box-shadow: 0px -4px 10px rgba(49, 49, 49, 0.05);
    }

    .cart-item-grid {
      grid-template-areas:
        "cart-item-image cart-item-name cart-item-name cart-item-price"
        "cart-item-image cart-item-category cart-item-category cart-item-category"
        "cart-item-image cart-item-customizations cart-item-customizations cart-item-quantity"
        "cart-item-image cart-item-actions cart-item-actions cart-item-actions";
      grid-template-columns: min-content 1fr 1fr 1fr;
      row-gap: 0.5rem;
    }
  }

  .product-card-sizer {
    --gap: 16px;
    --padding: 75px;
    min-width: calc(
      (100vw - (var(--product-card-sizer-count) * var(--gap)) - var(--padding)) / var(--product-card-sizer-count)
    );
  }

  @media screen(sm) {
    .product-card-sizer {
      --padding: 50px;
      --gap: 8px;
      --product-card-sizer-count: 2;
      min-width: calc(
        (640px - (var(--product-card-sizer-count) * var(--gap)) - var(--padding)) / var(--product-card-sizer-count)
      );
    }
  }

  @media screen(md) {
    .product-card-sizer {
      --padding: 50px;
      --gap: 16px;
      --product-card-sizer-count: 2;
      min-width: calc(
        (768px - (var(--product-card-sizer-count) * var(--gap)) - var(--padding)) / var(--product-card-sizer-count)
      );
    }
  }

  @media screen(lg) {
    .product-card-sizer {
      min-width: calc(
        (1024px - (var(--product-card-sizer-count) * var(--gap)) - var(--padding)) / var(--product-card-sizer-count)
      );
    }
  }

  @media screen(xl) {
    .product-card-sizer {
      min-width: calc(
        (1280px - (var(--product-card-sizer-count) * var(--gap)) - var(--padding)) / var(--product-card-sizer-count)
      );
    }
  }

  @media screen(2xl) {
    .product-card-sizer {
      min-width: calc(
        (1536px - (var(--product-card-sizer-count) * var(--gap)) - var(--padding)) / var(--product-card-sizer-count)
      );
    }
  }

  .product-card-sizer-3 {
    --product-card-sizer-count: 3;
  }
  .product-card-sizer-4 {
    --product-card-sizer-count: 4;
  }
  .product-card-sizer-5 {
    --product-card-sizer-count: 5;
  }
  .product-card-sizer-6 {
    --product-card-sizer-count: 6;
  }
}
